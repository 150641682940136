import React from 'react';

const Ticker = () => {
	return (
		<section className="c-logos-ticker">
			<div className="c-logos-ticker__content">
				<div className="c-logos-ticker__row">
					<div className="c-logos-ticker__logo c-logos-ticker__logo--forbes">
						<img
							className="o-fluid-img"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/forbes.svg"
							alt=""
						/>
					</div>
					<div className="c-logos-ticker__logo c-logos-ticker__logo--abc">
						<img
							className="o-fluid-img"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/abc.svg"
							alt=""
						/>
					</div>
					<div className="c-logos-ticker__logo c-logos-ticker__logo--nbc">
						<img
							className="o-fluid-img"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/nbc.svg"
							alt=""
						/>
					</div>
					<div className="c-logos-ticker__logo c-logos-ticker__logo--nyt">
						<img
							className="o-fluid-img"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/nyt-new.svg"
							alt=""
						/>
					</div>
					<div className="c-logos-ticker__logo c-logos-ticker__logo--cbs">
						<img
							className="o-fluid-img"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/cbs.svg"
							alt=""
						/>
					</div>
					<div className="c-logos-ticker__logo c-logos-ticker__logo--webmd">
						<img
							className="o-fluid-img"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/webmd.svg"
							alt=""
						/>
					</div>
					<div className="c-logos-ticker__logo c-logos-ticker__logo--wall-st">
						<img
							className="o-fluid-img"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/wall-st-journal.svg"
							alt=""
						/>
					</div>
					<div className="c-logos-ticker__logo c-logos-ticker__logo--forbes">
						<img
							className="o-fluid-img"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/forbes.svg"
							alt=""
						/>
					</div>
					<div className="c-logos-ticker__logo c-logos-ticker__logo--abc">
						<img
							className="o-fluid-img"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/abc.svg"
							alt=""
						/>
					</div>
					<div className="c-logos-ticker__logo c-logos-ticker__logo--nbc">
						<img
							className="o-fluid-img"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/nbc.svg"
							alt=""
						/>
					</div>
					<div className="c-logos-ticker__logo c-logos-ticker__logo--nyt">
						<img
							className="o-fluid-img"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/nyt-new.svg"
							alt=""
						/>
					</div>
					<div className="c-logos-ticker__logo c-logos-ticker__logo--cbs">
						<img
							className="o-fluid-img"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/cbs.svg"
							alt=""
						/>
					</div>
					<div className="c-logos-ticker__logo c-logos-ticker__logo--webmd">
						<img
							className="o-fluid-img"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/webmd.svg"
							alt=""
						/>
					</div>
					<div className="c-logos-ticker__logo c-logos-ticker__logo--wall-st">
						<img
							className="o-fluid-img"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/wall-st-journal.svg"
							alt=""
						/>
					</div>
					<div className="c-logos-ticker__logo c-logos-ticker__logo--forbes">
						<img
							className="o-fluid-img"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/forbes.svg"
							alt=""
						/>
					</div>
					<div className="c-logos-ticker__logo c-logos-ticker__logo--abc">
						<img
							className="o-fluid-img"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/abc.svg"
							alt=""
						/>
					</div>
					<div className="c-logos-ticker__logo c-logos-ticker__logo--nbc">
						<img
							className="o-fluid-img"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/nbc.svg"
							alt=""
						/>
					</div>
					<div className="c-logos-ticker__logo c-logos-ticker__logo--nyt">
						<img
							className="o-fluid-img"
							src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/nyt-new.svg"
							alt=""
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Ticker;
