import React from 'react';
import LandingPageCta from '../LandingPageCta';

const Header = () => {
	return (
		<section className="c-index-hero">
			<div className="o-wrapper">
				<div className="c-index-hero__content">
					<div className="c-index-hero__content-left">
						<h1 className="c-index-hero__title">hear.com’s hearing aids will change your life</h1>
						<LandingPageCta classes="c-cta--center-mobile track-cta-hero">
							Check if you qualify
							<br />
							for a no-risk trial
						</LandingPageCta>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Header;
