import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import ButtonCta from '../components/ButtonCta';
import CareerSlider from '../components/careers/CareerSlider';
import Jobs from '../components/careers/Jobs/Jobs';
import CustomBreadcrumb from '../components/CustomBreadcrumb';

const Careers = ({ pageContext, location }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	useEffect(() => {
		window.addEventListener(
			'sessionLayerReady',
			() => {
				const referrer = new URLSearchParams(window.location.search).get('referrer');
				if (!referrer) {
					return;
				}
				window.sessionLayer.log('US_OLD_CAREERS_PAGE_REDIRECT', { referrer, userAgent: navigator.userAgent });
			},
			{ once: true }
		);
	}, []);

	return (
		<Layout fontSerif={true}>
			<Seo
				title="Careers | hear.com"
				description="We believe that in today's world, everyone should hear well to live well. That's why we're on a mission to advance hearing care and deliver the magic of hearing. Join our team and help people thrive by keeping them happy, healthy, sharp, and connected."
				image="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/og-image-careers.jpg"
			/>
			<div className="o-wrapper">
				<div className="o-row-2p5">
					<section className="c-content-hero">
						<video
							className="o-fluid-img c-content-hero__img"
							data-object-fit="cover"
							src="https://res.cloudinary.com/dhqvlsb3l/video/upload/f_auto,q_auto:good/v1/assets/na/seo-website/careers/careers-hero.mp4"
							poster="https://res.cloudinary.com/dhqvlsb3l/video/upload/f_auto,q_auto:good/v1/assets/na/seo-website/careers/careers-hero.jpg"
							autoPlay
							loop
							playsInline
							muted
						></video>
						<h1 className="c-content-hero__headline">Careers at hear.com</h1>
						<div className="c-content-hero__divider"></div>
					</section>
				</div>
				<div className="o-row-2p5">
					<CustomBreadcrumb location={location} crumbs={crumbs} />
				</div>
				<div className="o-row-2p5">
					<Jobs />
				</div>
				<div className="o-row-3">
					<ButtonCta url="https://boards.greenhouse.io/hearcom/" copy="open positions" classes="c-cta--content" />
				</div>
				<h2 className="c-md-h2">We're on a mission</h2>
				<p className="c-md-p">
					We believe that in today's world, everyone should hear well to live well. That's why we're on a mission to
					advance hearing care and deliver the magic of hearing. Join our team and help people thrive by keeping them
					happy, healthy, sharp, and connected.
				</p>
				<h4 className="c-md-h4">About us</h4>
				<div className="o-row-3p5">
					<div className="c-careers-stats">
						<div className="c-careers-stats__item">
							<p className="c-careers-stats__number">2012</p>
							<p className="c-careers-stats__text">Founded</p>
						</div>
						<div className="c-careers-stats__item">
							<p className="c-careers-stats__number">8</p>
							<p className="c-careers-stats__text">Countries</p>
						</div>
						<div className="c-careers-stats__item">
							<p className="c-careers-stats__number">1300</p>
							<p className="c-careers-stats__text">Colleagues</p>
						</div>
						<div className="c-careers-stats__item">
							<p className="c-careers-stats__number">25</p>
							<p className="c-careers-stats__text">Nationalities</p>
						</div>
					</div>
				</div>
				<div className="o-row-3p5">
					<picture>
						<source
							media="(min-width: 600px)"
							srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/careers/hear-com-team-desk.jpg"
						/>
						<source
							media="(max-width: 599px)"
							srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/careers/hear-com-team.jpg"
						/>
						<img
							className="o-fluid-img"
							srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/careers/hear-com-team-desk.jpg"
							alt="hear.com team"
							loading="lazy"
						/>
					</picture>
				</div>
				<h2 className="c-md-h2">From tiny start-up to the fastest-growing hearing care company in the world</h2>
				<p className="c-md-p">
					We've gone from a handful of employees in a tiny Berlin office to over 1300 employees globally in little over
					a decade. We came to the US in 2015 and have quickly grown offices in both Miami and Denver. With hearing
					technology moving at the speed of light, we're continuing to expand and innovate to help as many people as
					possible see the life-changing benefits of better hearing.
				</p>
				<h2 className="c-md-h2">Our Values</h2>
				<div className="c-careers-grid">
					<div className="c-careers-grid__card">
						<div className="o-row-1">
							<img
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/careers/customer-first.svg"
								className="c-careers-grid__card-img"
								alt=""
							/>
						</div>
						<div className="o-row-1">
							<p className="c-careers-grid__card-title">We put customers first</p>
						</div>
						<p className="c-careers-grid__card-copy">
							Our real measure of success is the number of people we make happy with hearing aids.
						</p>
					</div>
					<div className="c-careers-grid__card">
						<div className="o-row-1">
							<img
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/careers/together.svg"
								className="c-careers-grid__card-img"
								alt=""
							/>
						</div>
						<div className="o-row-1">
							<p className="c-careers-grid__card-title">We work together</p>
						</div>
						<p className="c-careers-grid__card-copy">
							All meaningful progress at hear.com is achieved by teamwork & collaboration. We give honest feedback so we
							become better.
						</p>
					</div>
					<div className="c-careers-grid__card">
						<div className="o-row-1">
							<img
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/careers/data.svg"
								className="c-careers-grid__card-img"
								alt=""
							/>
						</div>
						<div className="o-row-1">
							<p className="c-careers-grid__card-title">We decide with data</p>
						</div>
						<p className="c-careers-grid__card-copy">
							Not only do we use our creativity to come up with new ideas, but we also rely on performance metrics to
							support them.
						</p>
					</div>
					<div className="c-careers-grid__card">
						<div className="o-row-1">
							<img
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/careers/big.png"
								className="c-careers-grid__card-img"
								alt=""
							/>
						</div>
						<div className="o-row-1">
							<p className="c-careers-grid__card-title">We go BIG</p>
						</div>
						<p className="c-careers-grid__card-copy">
							Everything we spend time on has an opportunity cost, so we rank our tasks by impact and only focus on the
							most valuable.
						</p>
					</div>
					<div className="c-careers-grid__card">
						<div className="o-row-1">
							<img
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/careers/boundaries.svg"
								className="c-careers-grid__card-img"
								alt=""
							/>
						</div>
						<div className="o-row-1">
							<p className="c-careers-grid__card-title">We push boundaries</p>
						</div>
						<p className="c-careers-grid__card-copy">
							The world is constantly changing, along with our marketing environment and customers. We believe we can
							help shape the future.
						</p>
					</div>

					<div className="c-careers-grid__card">
						<div className="o-row-1">
							<img
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/careers/celebrate-success.svg"
								className="c-careers-grid__card-img"
								alt=""
							/>
						</div>
						<div className="o-row-1">
							<p className="c-careers-grid__card-title">We celebrate success</p>
						</div>
						<p className="c-careers-grid__card-copy">We are a family at hear.com and we celebrate wins as a team.</p>
					</div>
				</div>
				<div className="o-row-3p5">
					<picture>
						<source
							media="(min-width: 600px)"
							srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/careers/office-desk.jpg"
						/>
						<source
							media="(max-width: 599px)"
							srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/careers/office.jpg"
						/>
						<img
							className="o-fluid-img"
							srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/careers/office-desk.jpg"
							alt="hear.com office"
							loading="lazy"
						/>
					</picture>
				</div>
				<h2 className="c-md-h2">One team, one dream</h2>
				<p className="c-md-p">
					We work in cross-functional teams instead of splitting everyone up into departments. We don't believe in
					hierarchy, which gives everyone an opportunity to share their ideas and opinions. So whether you're a finance
					intern or a senior marketing specialist, your voice is heard at hear.com
				</p>
				<h2 className="c-md-h2">
					Our office locations -{' '}
					<span className="u-inline-block" style={{ fontWeight: 300, fontSize: '0.8em' }}>
						Where do you want to work?
					</span>
				</h2>
				<div className="o-row-3p5">
					<div className="c-careers-offices">
						<div className="c-careers-offices__row">
							<div className="c-careers-offices__item c-careers-offices__item--image">
								<div className="c-careers-offices__overlay--image"></div>
								<img
									className="c-careers-offices__img"
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/careers/o_01.jpg"
									alt=""
								/>
							</div>
							<div className="c-careers-offices__item c-careers-offices__item--text">
								<div className="c-careers-offices__overlay--text"></div>
								<div className="c-careers-offices__text">
									<p className="c-careers-offices__name">Coral Gables, Florida</p>
									<p className="c-careers-offices__body">
										Our US journey started in beautiful Coral Gables and this office continues to thrive on the culture
										that kick-started our growth. You'll find Marketing, Sales and Operations come together in our
										casual space, in-house game room or at the ping pong table.
									</p>
								</div>
							</div>
							<div className="c-careers-offices__item c-careers-offices__item--image">
								<div className="c-careers-offices__overlay--image"></div>
								<img
									className="c-careers-offices__img"
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/david-2025.png"
									alt=""
								/>
							</div>
						</div>
						<div className="c-careers-offices__row">
							<div className="c-careers-offices__item c-careers-offices__item--image">
								<div className="c-careers-offices__overlay--image"></div>
								<img
									className="c-careers-offices__img"
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/careers/o_03.jpg"
									alt=""
								/>
							</div>
							<div className="c-careers-offices__item c-careers-offices__item--image">
								<div className="c-careers-offices__overlay--image"></div>
								<img
									className="c-careers-offices__img"
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/careers/o_04.jpg"
									alt=""
								/>
							</div>
							<div className="c-careers-offices__item c-careers-offices__item--text">
								<div className="c-careers-offices__overlay--text"></div>
								<div className="c-careers-offices__text">
									<p className="c-careers-offices__name">Denver, Colorado</p>
									<p className="c-careers-offices__body">
										Our newest and most innovative office yet, Denver houses our state-of-the-art hearing aid studio and
										brings together a number of cross-functional teams. With open space, awesome colleagues and
										breathtaking views of the Rockies, this campus thrives.
									</p>
								</div>
							</div>
						</div>
						<div className="c-careers-offices__row">
							<div className="c-careers-offices__item c-careers-offices__item--text">
								<div className="c-careers-offices__overlay--text"></div>
								<div className="c-careers-offices__text">
									<p className="c-careers-offices__name">Your home office</p>
									<p className="c-careers-offices__body">
										If you don’t live near our two offices, don’t worry. With today's technology, you can collaborate
										with colleagues, connect with customers, and have a big impact at hear.com while working from the
										comforts of your home.
									</p>
								</div>
							</div>
							<div className="c-careers-offices__item c-careers-offices__item--image">
								<div className="c-careers-offices__overlay--image"></div>
								<img
									className="c-careers-offices__img"
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/careers/o_05.jpg"
									alt=""
								/>
							</div>
							<div className="c-careers-offices__item c-careers-offices__item--image">
								<div className="c-careers-offices__overlay--image"></div>
								<img
									className="c-careers-offices__img"
									src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/careers/o_06.jpg"
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
				<h2 className="c-md-h2">Grow with us</h2>
				<p className="c-md-p">
					To grow the company, it's important to develop our people. With the rate of innovation at hear.com, we provide
					the environment, encouragement, and opportunities to develop both professionally and personally. And we give
					you the freedom to find your potential.
				</p>
				<h2 className="c-md-h2">Employee spotlight</h2>
				<div className="o-row-3p5">
					<div className="c-content-hero__divider"></div>
				</div>
				<div className="o-row-3p5">
					<CareerSlider />
				</div>
				<h2 className="c-md-h2">Perks and Benefits</h2>
				<div className="c-careers-grid">
					<div className="c-careers-grid__card">
						<div className="o-row-1">
							<img
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/careers/drinks-snacks.svg"
								className="c-careers-grid__card-img"
								alt=""
							/>
						</div>
						<div className="o-row-1">
							<p className="c-careers-grid__card-title">Free Snacks and Drinks</p>
						</div>
						<p className="c-careers-grid__card-copy">
							We offer a wide variety of snacks, lunch, energy drinks and coffee to help boost your energy.
						</p>
					</div>
					<div className="c-careers-grid__card">
						<div className="o-row-1">
							<img
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/careers/shoe.svg"
								className="c-careers-grid__card-img"
								alt=""
							/>
						</div>
						<div className="o-row-1">
							<p className="c-careers-grid__card-title">Casual Attire</p>
						</div>
						<p className="c-careers-grid__card-copy">
							Whether you're a suit kind of person, or jeans and t-shirt, we just want you to feel comfortable.
						</p>
					</div>
					<div className="c-careers-grid__card">
						<div className="o-row-1">
							<img
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/careers/rocket.svg"
								className="c-careers-grid__card-img"
								alt=""
							/>
						</div>
						<div className="o-row-1">
							<p className="c-careers-grid__card-title">Company Culture</p>
						</div>
						<p className="c-careers-grid__card-copy">
							We frequently host team events where everyone gathers to celebrate all our accomplishments.
						</p>
					</div>
					<div className="c-careers-grid__card">
						<div className="o-row-1">
							<img
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/careers/yoga.svg"
								className="c-careers-grid__card-img"
								alt=""
							/>
						</div>
						<div className="o-row-1">
							<p className="c-careers-grid__card-title">Benefit Packages</p>
						</div>
						<p className="c-careers-grid__card-copy">
							We got all your personal needs covered with our amazing medical, dental, vision and 401k plans.
						</p>
					</div>
				</div>
				<div className="o-row-3p5">
					<picture>
						<source
							media="(min-width: 600px)"
							srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/careers/glassdoor-desk.jpg"
						/>
						<source
							media="(max-width: 599px)"
							srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/careers/glassdoor.png"
						/>
						<img
							className="o-fluid-img c-glassdoor-img-border"
							srcSet="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/careers/glassdoor-desk.jpg"
							alt="hearing aids"
							loading="lazy"
							id="scroll-point"
						/>
					</picture>
				</div>
			</div>
		</Layout>
	);
};

export default Careers;
